<template>
  <div class="wap-auth user">
    <headerBar :title="$t('xin-zeng-di-zhi-0')" @back="back"></headerBar>

    <div class="main wap-page-form">
      <div class="form-item">
        <div class="input">
          <Field
            v-model="form.FullName"
            :placeholder="$t('shou-huo-ren-xing-ming')"
          >
          </Field>
        </div>
      </div>

      <div class="form-item">
        <div class="input">
          <Field
            v-model="form.phone"
            :placeholder="$t('qing-shu-ru-shou-ji-hao-6')"
          >
            <template #left-icon>
              <div class="flex-center" @click="changeCountry">
                {{ form.phonePre }}
                <i class="el-icon-caret-bottom"></i>
              </div>
            </template>
          </Field>
        </div>
      </div>
      <div class="form-item">
        <div class="input">
          <Field v-model="form.Country" :placeholder="$t('guo-jia')"> </Field>
        </div>
      </div>
      <div class="form-item">
        <div class="input">
          <Field v-model="form.Province" :placeholder="$t('sheng-fen')">
          </Field>
        </div>
      </div>
      <div class="form-item">
        <div class="input">
          <Field v-model="form.City" :placeholder="$t('cheng-shi')"> </Field>
        </div>
      </div>
      <div class="form-item">
        <div class="input">
          <Field
            type="textarea"
            v-model="form.Address"
            :placeholder="$t('di-zhi')"
          >
          </Field>
        </div>
      </div>
      <div class="form-item">
        <div class="input">
          <Field v-model="form.ZipCode" :placeholder="$t('you-bian')"> </Field>
        </div>
      </div>

      <div class="form-btn">
        <Button class="submit-btn" @click="submit">{{ $t('xin-zeng') }}</Button>
      </div>
    </div>

    <CountryList
      :showDialog.sync="showDialog"
      :phonePre.sync="form.phonePre"
    ></CountryList>
  </div>
</template>
<script>
import { Button, DatetimePicker, Popup, Switch as vanSwitch, Field } from 'vant'
import headerBar from '@/components/header'
import CountryList from '@/components/countryList'
import { getToken } from '@/utils/auth'
import { addShippingAddress } from '@/api/user'
export default {
  components: {
    CountryList,
    Button,
    headerBar,
    DatetimePicker,
    vanSwitch,
    Popup,
    Field
  },
  data() {
    return {
      avatar: '',
      form: {
        phone: '',
        phonePre: '+86'
      },
      headers: {},
      showDialog: false,
      showPassword: false,
      eyeUrl: require('@/assets/imgs/icon-eye-black.png'),
      eyeUrl2: require('@/assets/imgs/icon-eye2-black.png')
    }
  },
  computed: {
    langList() {
      return this.$store.state.langList
    },
    lang() {
      return this.$store.state.lang
    }
  },
  mounted() {
    this.headers = {
      Token: getToken()
    }
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    changeCountry() {
      this.showDialog = true
    },
    submit() {
      if (!this.form.FullName) {
        this.$toast(this.$t('qing-shu-ru-xing-ming'))
        return
      }
      if (!this.form.phone) {
        this.$toast(this.$t('qing-shu-ru-shou-ji-hao-1'))
        return
      }
      if (!this.form.Country) {
        this.$toast(this.$t('qing-shu-ru-guo-jia'))
        return
      }
      if (!this.form.Province) {
        this.$toast(this.$t('qing-shu-ru-sheng-fen'))
        return
      }
      if (!this.form.City) {
        this.$toast(this.$t('qing-shu-ru-cheng-shi-0'))
        return
      }
      if (!this.form.Address) {
        this.$toast(this.$t('qing-shu-ru-di-zhi'))
        return
      }
      if (!this.form.ZipCode) {
        this.$toast(this.$t('qing-shu-ru-you-bian-0'))
        return
      }
      addShippingAddress({
        Phone: this.form.phonePre + this.form.phone,
        FullName: this.form.FullName,
        Country: this.form.Country,
        Province: this.form.Province,
        City: this.form.City,
        Address: this.form.Address,
        ZipCode: this.form.ZipCode
      }).then((res) => {
        if (res.code == 0) {
          this.$toast(this.$t('tian-jia-cheng-gong'))
          setTimeout(() => {
            this.back()
          }, 1500)
        } else {
          this.$toast(res.msg)
        }
      })
    }
  }
}
</script>